// The locale our app first shows
const defaultLocale = "en";
// The active locale
let locale;
// Gets filled with active locale translations
let translations = {
  en: {
    "hero-1": "My name is",
    "hero-2": "I'm the developer you need!",
    "hero-3": "Learn more",
    "about-1": "ABOUT ME",
    "about-2":
      "I graduated with a bachelor of engineering in automated production in april 2022. My background is more in automation but throughout my last semester in school I found out that I liked web development a lot with my end of study project which was to build a virtual wardrobe. To do so we had to make a website and a mobile application. I think that's when I realised that this is a career I want to pursue.",
    "about-3":
      "Over the past two years, I have had the opportunity to work as a full-stack developer using technologies such as Next.js, Node/Express.js, MySQL, AWS, TypeScript, among others. In my personal time, I continue to create various projects with these technologies and also experiment with new ones from time to time. As someone who loves to learn constantly, I always enjoy using new libraries/technologies out of curiosity. You will find below some of the projects I've worked on.",
    "about-4": "View Resume",
    "about-5": "assets/CV_LUCASPIERRU_english_.pdf",
    "projet1-1": "Match day",
    "projet1-2":
      "This platform allows you to register for soccer leagues. The whole registration process is handled by the platform, including payments through Stripe. People can also register to be a referee by entering their information, including check specimens, to get payouts. Admins can create a season that would be associated with multiple leagues; once the leagues are completed, the admin will be able to generate the schedule on command. Players have their own dashboard, where they can keep track of their schedule and statistics for the players and similarly Referees have a dashboard to keep track of schedules and register games they want to referee.",
    "projet2-1": "CRWN Clothing",
    "projet2-2":
      "This is clothing store built with React.js where you can do everything that you usually do on a clothing store. There are categories of clothes, you can add clothes to the cart and even checkout. You can also authenticate on the website with an email and password or with Google. The authentication was done with firebase. For checkout, payment is handle with Stripe's serverless functions.",
    contact: "lucaspierru7@gmail.com",
    "projet3-1": "SmartBrain",
    "projet3-2":
      "This React.js project allows you to detect a face in an image with help of the " +
      `<a href="https://www.clarifai.com/"/>Clarifai</a>` +
      " API. Once you get on the app you will have to register or sign in. The information you give will be sent to the backend. The backend server was made with node.js and express.js. The backend is linked to a postgreSQL database. The application is deployed on Heroku.",
    "see-live": "See Live",
    "source-code-1": "Frontend Source Code",
    "source-code-2": "Backend Source Code",
  },
  fr: {
    "hero-1": "Je m'appelle",
    "hero-2": "Le développeur dont vous avez besoin !",
    "hero-3": "En savoir plus",
    "about-1": "À PROPOS",
    "about-2":
      "Je suis diplômé d'un baccalauréat en génie de la production automatisée depuis avril 2022. Mes compétences sont plus en automatisation mais au cours de ma dernière session d'école, j'ai découvert que j'aimé le developpement web grâce à mon projet de fin d'études qui consistait à créer une garde robe virtuelle. Pour réaliser ce projet, nous avons dû créer un site web et une application mobile. Je pense que c'est lors de ce projet que j'ai réalisé que c'est une carrière que je voulais poursuivre.",
    "about-3":
      "Au cours des deux dernières années j'ai eu l'opportunité de travailler comme developpeur full-stack en utilisant des technologies comme next.js, node/express.js, MySQL, AWS, typescript entre autres et dans mon temps personnel je continue de créer divers projets avec ces technologies et, également, en utilisant de nouvelles technologies de temps en temps, étant quelqu'un qui aime apprendre constamment, j'aime toujours utiliser une nouvelle librairie/technologies par curiosité. Vous trouverez ci-dessous certains des projets sur lesquels j'ai travaillé.",
    "about-4": "Voir mon CV",
    "about-5": "assets/CV_LUCASPIERRU_.pdf",
    "projet1-1": "MatchDay",
    "projet1-2":
      "Cette plateforme vous permet de vous inscrire à des ligues de soccer à Montréal. Tout le processus d'inscription est géré par la plateforme, y compris les paiements via Stripe. Les personnes peuvent également s'inscrire en tant qu'arbitre en fournissant leurs informations, y compris des spécimens de chèques, pour recevoir des paiements. Les administrateurs peuvent créer une saison associée à plusieurs ligues ; une fois les ligues terminées, l'administrateur pourra générer le calendrier à la demande. Les joueurs ont leur propre tableau de bord, où ils peuvent suivre leur emploi du temps et leurs statistiques, et de même, les arbitres disposent d'un tableau de bord pour suivre leurs horaires et s'inscrire aux matchs qu'ils souhaitent arbitrer.",
    "projet2-1": "CRWN Clothing",
    "projet2-2":
      "Ce projet est un magasin de vêtements en ligne réalisé avec React.js. Vous avez differentes catégories de vêtements et vous pouvez ajouter les vêtements dans votre panier ainsi que confirmer la commande. Vous pouvez aussi vous authentifier avec un e-mail et mot de passe ou bien avec votre compte Google. L'authentification a été faite avec firebase. Pour les paiements, on gère cela avec Stripe.",
    contact: "lucaspierru7@gmail.com",
    "projet3-1": "SmartBrain",
    "projet3-2":
      "Ce projet React.js vous permet de détecter le visage d'une image en entrant le lien de celle -ci et avec l'aide de l'API " +
      `<a href="https://www.clarifai.com/"/>Clarifai</a>` +
      ". Une fois sur l'application vous devez vous enregistrer ou vous connecter. Les informations que vous donner seront envoyées au serveur node.js et express.js. Le serveur est connecté à une base de données postgreSQL. L'application est déployée sur Heroku.",
    "see-live": "Voir Site Web",
    "source-code-1": "Code Source Frontend",
    "source-code-2": "Code Source Serveur",
  },
};
let translate;
// When the page content is ready...
document.addEventListener("DOMContentLoaded", () => {
  // Translate the page to the default locale
  setLocale(defaultLocale);
});
// Load translations for the given locale and translate
// the page to this locale
function setLocale(newLocale) {
  if (newLocale === locale) return;
  const newTranslations = translations[newLocale];
  const btn = document.getElementById("btn");
  locale = newLocale;
  if (locale === "en") {
    btn.innerText = "Français";
  } else {
    btn.innerText = "English";
  }
  translate = newTranslations;
  translatePage();
  return locale;
}
// Replace the inner text of each element that has a
// data-i18n-key attribute with the translation corresponding
// to its data-i18n-key
function translatePage() {
  document.querySelectorAll("[data-i18n-key]").forEach(translateElement);
}
// Replace the inner text of the given HTML element
// with the translation in the active locale,
// corresponding to the element's data-i18n-key
function translateElement(element) {
  const key = element.getAttribute("data-i18n-key");
  const translation = translate[key];
  if (key === "about-4") {
    element.setAttribute("href", translate["about-5"]);
  }
  element.innerHTML = translation;
}

// When the page content is ready
document.addEventListener("DOMContentLoaded", () => {
  setLocale(defaultLocale);
});
// Whenever the user selects a new locale, we
// load the locale's translations and update
// the page

function buttonLanguage() {
  const btn = document.getElementById("btn");
  if (btn.innerText === "Français") {
    setLocale("fr");
  } else {
    setLocale("en");
  }
}
